<template>
    <div class="hstack">
    <!--
        <button class="btn btn-sm btn-link navigator-button" :title="$t('Go to first row')" :disabled="dataObject.currentIndex <= 0"
            @click="navigateToFirstRow()">
            <i class="bi bi-chevron-bar-left"></i>
        </button>
        -->
        <h6 class="mx-2 px-2 mb-1">
            {{$t('Record')}} #{{dataObject.currentIndex+1}}
        </h6>
        <button class="btn btn-sm btn-link m-1 navigator-button" :title="$t('Go to previous row')" :disabled="dataObject.currentIndex <= 0 || dataObject.state.isLoading"
            @click="navigateRow(true)">
            <i class="bi bi-chevron-left"></i>
            <!--
            {{$t('Previous')}}
            -->
        </button>

<!--
        <span class="text-muted py-1" style="font-size: larger;">
            {{props.dataObject.currentIndex+1}} / {{rowCount}}
        </span>
        <div v-if="isRowCountLoading" class="spinner-border spinner-border-sm align-self-center" role="status">
            <span class="sr-only"></span>
        </div>
        <button v-else-if="!rowCount" class="btn btn-sm btn-link navigator-button" :title="$t('Load row count')" @click="loadRowcount()">
            <i class="bi bi-question-circle"></i>
        </button>
        -->

        <button class="btn btn-sm btn-link mx-1 navigator-button" :title="$t('Go to next row')" 
            @click="navigateRow()" :disabled="isRowCountLoading || !nextRowExists ||(rowCount && (rowCount-1) === dataObject.currentIndex) || dataObject.state.isLoading">
            <i class="bi bi-chevron-right"></i>
            <!--
            {{$t('Next')}}
            -->
        </button>
        <!--
        <button class="btn btn-sm btn-link navigator-button" :title="$t('Go to last row')" :disabled="isRowCountLoading || (rowCount && (rowCount-1) === dataObject.currentIndex)" @click="navigateToLastRow()">
            <i class="bi bi-chevron-bar-right"></i>
        </button>
        -->


        <!--
          <button class="btn btn-link btn-sm mx-1" :title="$t('Reload detail')"
            @click="dataGridControl.menuTabs?.iframe?.contentWindow.location.reload()" :disabled="!dataGridControl.menuTabs?.iframe || dataObject.state.isLoading">
            <i class="bi bi-arrow-clockwise fs-6"></i>
        </button>
        -->
    </div>
</template>

<script setup lang="ts">
/**
 * Component for navigating between rows on the DataObject. 
 * @definition
 */
import type DataObject from 'o365.modules.DataObject.ts';
import type DataGridControl from 'o365.controls.DataGrid.ts';

import { computed } from 'vue';

const props = defineProps<{
    dataObject: DataObject,
    dataGridControl?: DataGridControl
}>();

function navigateToFirstRow() {
    props.dataObject.setCurrentIndex(0);
    attemptGridScroll(0);
}

async function navigateToLastRow() {
    if (rowCount.value == null || rowCount.value === -1) {
        await loadRowcount();
    }
    if (props.dataObject.data[rowCount.value -1]) {
        props.dataObject.setCurrentIndex(rowCount.value - 1);
    } else {
        props.dataObject.once('DataLoaded', () => {
            props.dataObject.setCurrentIndex(rowCount.value - 1);
        });
    }
    attemptGridScroll(rowCount.value);
}

function navigateRow(pBackwards = false) {
    const currentIndex = props.dataObject.currentIndex;
    const increment = pBackwards ? -1 : 1;
    props.dataObject.setCurrentIndex(currentIndex + increment);
    attemptGridScroll();
}

function attemptGridScroll(pIndexOverride?: number) {
    if (props.dataGridControl?.getVerticalScrollViewport == null) { return; }
    const viewport: HTMLElement|undefined = props.dataGridControl.getVerticalScrollViewport();
    if (viewport == null) { return; }

    if (pIndexOverride != null) {
        viewport.scrollTop = pIndexOverride * 34;
        return;
    }

    const currentIndex = props.dataObject.currentIndex;
    const startIndex = Math.round(viewport.scrollTop / 34);
    const endIndex = startIndex + Math.round(viewport.clientHeight / 34);


    if (currentIndex + 2 > endIndex) {
        const position = (startIndex+1) * 34;
        viewport.scrollTop = position;
    } else if (currentIndex - 2 < startIndex) {
        const position = (startIndex-1) * 34;
        viewport.scrollTop = position;
    }
}

function loadRowcount() {
    return props.dataObject.recordSource.loadRowCounts({
        timeout: 30000
    });
}

const nextRowExists = computed(() => {
    return props.dataObject.data[props.dataObject.currentIndex+1] != null;
});

const rowCount = computed(() => {
    let rowCount = null;
    if (props.dataObject.filterObject.appliedFilterString) {
        rowCount = props.dataObject.filteredRowCount;
    } else {
        rowCount = props.dataObject.rowCount;
    }
    return rowCount === -1 ? null : rowCount;
});

const isRowCountLoading = computed(() => {
    if (props.dataGridControl) {
        return props.dataGridControl.state.isRowCountLoading;
    } else {
        return props.dataObject.isRowCountLoading;
    }
});

</script>

<style scoped>
</style>